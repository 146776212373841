import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

import Carousel from '../page-components/carousel/carousel';
import { ISectionColor } from '../../types/types';

const Testimonial = ({ text, author }: { text: string; author: string }) => (
  <div
    style={{ maxWidth: 800, height: '25rem' }}
    className="carousel-child text-white py-4 px-2 md:px-4 md:w-2/3 xl:w-1/2 w-10/12 bg-primary-lighter rounded"
  >
    <div className="border-contrast border-l-2 border-r-2 flex-center flex-col h-full px-1 md:px-2">
      <div style={{ height: '90%', overflowY: 'scroll' }} className="text-left text-primary py-4 px-1 md:px-2">
        <FaQuoteLeft className="mr-auto text-2xl" />
        <p style={{ lineHeight: '1.8rem' }} className="py-2 text-base mx-auto px-1 md:px-2">
          {`${text}`}
        </p>
        <FaQuoteRight className="ml-auto text-2xl" />
      </div>
      <h4 className="mt-2 italic font-semibold mb-2 text-lg text-primary">{author}</h4>
    </div>
  </div>
);

const Testimonials = ({ backgroundColor, textColor }: ISectionColor) => {
  const data: IQuery = useStaticQuery(graphql`
    query {
      allTestimonialsJson {
        edges {
          node {
            author
            text
          }
        }
      }
    }
  `);

  const testimonials = data.allTestimonialsJson.edges;

  return (
    <div className={`${backgroundColor} ${textColor} page-section-padding text-center flex flex-col items-center`}>
      <h3 className="text-xl mb-2 uppercase font-extrabold">What People Say</h3>
      <p>Don&apos;t just take it from me, here&apos;s what my current and former students have to say:</p>
      <div style={{ maxWidth: 1200 }} className="w-full">
        <Carousel>
          {testimonials.map(t => (
            <Testimonial key={t.node.author} author={t.node.author} text={t.node.text} />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Testimonials;

type ITestimonial = {
  node: {
    text: string;
    author: string;
  };
};

type IQuery = {
  allTestimonialsJson: {
    edges: ITestimonial[];
  };
};
