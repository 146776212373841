import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import Hero from '../components/homepage/hero';
import Divider from '../components/sections/sectionDivider';
import Modal from '../components/page-components/modal';
import SignupForm from '../components/page-components/forms/signupForm/main';
import {
  PriceBannerSection,
  PriceCardSection,
  HomeImageSection,
  Testimonials,
  Subscribe,
  CTA,
} from '../components/homepage/main';

const SignUpModal = ({ setShowModal, showModal }) => (
  <Modal
    ariaLabel="Mailing list sign up form"
    showModal={showModal}
    setShowModal={setShowModal}
    className="md:w-1/2 lg:w-5/12 xl:w-3/12"
  >
    <div className="px-5 py-8">
      <SignupForm setShowModal={setShowModal} />
    </div>
  </Modal>
);

const IndexPage = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Layout>
      <SEO title="Home" />
      <Hero />
      <Divider />
      <PriceCardSection backgroundColor="bg-primary-lightest" textColor="text-primary" />
      <Divider />
      <HomeImageSection backgroundColor="bg-primary" textColor="text-white" reverse />
      <Divider />
      <Testimonials backgroundColor="bg-primary-lightest" textColor="text-primary" />
      <Divider />
      <PriceBannerSection backgroundColor="bg-primary" textColor="text-white" />
      <Divider />
      <Subscribe
        showModal={showModal}
        setShowModal={setShowModal}
        backgroundColor="bg-primary-lightest"
        textColor="text-primary"
      />
      {showModal && <SignUpModal setShowModal={setShowModal} showModal={showModal} />}
      <Divider />
      <CTA backgroundColor="bg-primary" textColor="text-white" />
    </Layout>
  );
};

SignUpModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default IndexPage;
