import React, { FC, MouseEventHandler, useRef } from 'react';

import usePosition from './usePosition';

const buttonStyles =
  'absolute cursor-pointer top-1/2 z-10 bg-primary bg-opacity-50 rounded p-2 transition ease-in-out duration-500';

type IArrow = {
  onClick: MouseEventHandler<HTMLButtonElement>;
};

interface IRightArrow extends IArrow {
  hasItemsOnRight: boolean;
}

interface ILeftArrow extends IArrow {
  hasItemsOnLeft: boolean;
}

const LeftArrow = ({ hasItemsOnLeft, onClick }: ILeftArrow) => (
  <button
    onClick={onClick}
    type="button"
    aria-label="previous slide"
    className={`${buttonStyles} ${!hasItemsOnLeft && 'hidden'} left-0 left-arrow carousel-button`}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      viewBox="0 0 24 24"
      fill="none"
      stroke="#ffffff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 12H6M12 5l-7 7 7 7" />
    </svg>
  </button>
);

const RightArrow = ({ hasItemsOnRight, onClick }: IRightArrow) => (
  <button
    onClick={onClick}
    type="button"
    aria-label="next slide"
    className={`${buttonStyles} ${!hasItemsOnRight && 'hidden'} right-0 right-arrow carousel-button`}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      viewBox="0 0 24 24"
      fill="none"
      stroke="#ffffff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 12h13M12 5l7 7-7 7" />
    </svg>
  </button>
);

const Carousel: FC = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);

  const { hasItemsOnLeft, hasItemsOnRight, scrollLeft, scrollRight } = usePosition(ref);

  return (
    <div className="relative carousel-outer overflow-hidden py-4">
      <LeftArrow hasItemsOnLeft={hasItemsOnLeft} onClick={scrollLeft} />
      <RightArrow hasItemsOnRight={hasItemsOnRight} onClick={scrollRight} />
      <div className="carousel flex" ref={ref}>
        {children}
      </div>
    </div>
  );
};

export default Carousel;
