import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import ImageSection from '../sections/imageSection';

import { ISectionColor } from '../../types/types';

interface Props extends ISectionColor {
  reverse: boolean;
}

const HomeImageSection = ({ backgroundColor, textColor, reverse = false }: Props) => (
  <ImageSection
    backgroundColor={backgroundColor}
    textColor={textColor}
    reverse={reverse}
    heading="A structured guitar program from beginner to advanced, tailored to your needs"
    text="I’m genuinely proud of the learning pathway I have crafted, using my
    experience both as a performer/recording artist in the industry, and the
    many THOUSANDS of hours spent teaching and refining guitar resources
    over the years. My dream is to share this with as many people as I can,
    and to make a difference in people's lives by helping them to
    fulfil their musical hopes and dreams!"
  >
    <StaticImage
      src="../../images/guitar-head.jpg"
      layout="constrained"
      quality={95}
      placeholder="blurred"
      formats={['auto', 'webp', 'avif']}
      alt="Jonny Ball teaching guitar"
    />
  </ImageSection>
);

export default HomeImageSection;
