import React, { Dispatch, SetStateAction } from 'react';
import { ISectionColor } from '../../types/types';

import { LargePrimaryActionButton, PrimaryActionButton } from '../page-components/buttons/buttons';

interface Props extends ISectionColor {
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

const Subscribe = ({ setShowModal, backgroundColor, textColor }: Props) => (
  <div className={`${backgroundColor} ${textColor} page-section-padding text-center`}>
    <div className="mb-4 md:w-2/3 mx-auto">
      <h4 className="section-heading px-5 mb-4">
        Coming Soon: subscribe to the Jonny Ball Guitar Beginner&apos;s Course
      </h4>
      <p className="text-content mx-auto">
        We are extremely excited to announce the development of the JonnyBallGuitar beginner course. A unique, step by
        step structured and in depth course designed to guide any beginner through to the intermediate level! Stay tuned
        and sign up to receive updates
      </p>
    </div>
    <div className="hidden md:inline-block">
      <LargePrimaryActionButton label="Sign Up for Updates" onClick={() => setShowModal(true)} />
    </div>
    <div className="md:hidden inline-block">
      <PrimaryActionButton label="Sign Up for Updates" onClick={() => setShowModal(true)} />
    </div>
  </div>
);

export default Subscribe;
