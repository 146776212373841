import React, { useState, Dispatch, SetStateAction } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  ContrastActionButton,
  ContrastLinkButton,
  LargeContrastActionButton,
  PrimaryActionButton,
} from '../page-components/buttons/buttons';
import Modal from '../page-components/modal';

import { SmallContrastPriceBanner } from '../page-components/banners/banners';
import VideoContainer from '../page-components/videoEmbedContainer';

const HeroModal = ({
  setShowModal,
  showModal,
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
}) => (
  <Modal
    ariaLabel="Discover what it's all about"
    setShowModal={setShowModal}
    showModal={showModal}
    className="md:w-2/3 xl:w-1/2 text-center text-white py-5 px-4"
  >
    <SmallContrastPriceBanner
      additionalStyle="w-auto px-5 md:p-5 mb-4 md:absolute md:-top-16 lg:-top-12 -left-12"
      heading="Lessons"
      price="£17.50"
      timeScale="30 mins"
    />
    <div className="px-4 md:py-5">
      <h2 className="subheading mb-2 md:mb-5 uppercase text-primary text-center">Discover what it&apos;s all about!</h2>
      <VideoContainer>
        <iframe
          className="h-full absolute left-0 top-0 w-full"
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/5dY97HIIpaY"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </VideoContainer>
      <div className="flex items-center justify-around mx-auto pt-4 max-w-xs">
        <ContrastLinkButton label="Book a Lesson" to="/contact/" />
        <PrimaryActionButton label="Dismiss" onClick={() => setShowModal(false)} />
      </div>
    </div>
  </Modal>
);

const Hero = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <div
      style={{ maxHeight: 700 }}
      className="flex flex-col-reverse md:flex-row items-center justify-evenly bg-hero relative p-2 md:py-12"
    >
      <StaticImage
        src="../../images/newhero-masked.jpg"
        className="md:w-1/2"
        layout="constrained"
        width={800}
        quality={100}
        placeholder="blurred"
        formats={['auto', 'webp', 'avif']}
        alt="Jonny Ball music teacher"
      />
      <div className="md:px-5 md:w-1/2 mb-2 md:mb-0 text-center">
        <div className="text-white flex-center flex-col py-5 md:py-10 px-5 text-center">
          <h1 className="text-3xl md:mb-4 lg:text-5xl uppercase">Learn Guitar with Jonny Ball</h1>
          <p className="mb-2 md:mb-4">
            Take the next step in your guitar journey with bespoke lessons from a seasoned teacher, performer and
            recording artist.
          </p>
          <div className="lg:hidden">
            <ContrastActionButton onClick={() => setShowModal(true)} label="WATCH NOW" />
          </div>
          <div className="hidden lg:inline-block">
            <LargeContrastActionButton onClick={() => setShowModal(true)} label="WATCH NOW" />
          </div>
        </div>
      </div>
      {showModal && <HeroModal setShowModal={setShowModal} showModal={showModal} />}
    </div>
  );
};

export default Hero;
