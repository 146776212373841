import React, { MouseEventHandler } from 'react';
import { BsCheckCircle } from 'react-icons/bs';

import { ContrastLinkButton, PrimaryActionButton } from '../buttons/buttons';

const cardWrapperStyles = 'p-4 w-auto text-center text-primary ';
const cardStyles = 'flex flex-col justify-between items-center shadow-2xl border-2 border-white rounded';
const cardHeaderSectionStyles = 'px-2 py-6 bg-primary-lighter w-full border-b-2 border-white';
const cardHeaderTextStyles = 'text-xl montserrat font-bold';
const cardBodyStyles = 'py-6 bg-primary-lightest w-full';
const cardListStyles = 'text-left px-5';
const cardListItemStyles = 'py-1 flex items-center';
const cardFooterStyles = 'py-4 px-2 bg-primary-lighter border-t-2 border-white w-full flex flex-col items-center';
const cardPriceStyles = 'tracking-wider text-xl font-bold';

type ICardBase = {
  heading: string;
  listItems: string[];
  price: number;
};

interface IPriceCard extends ICardBase {
  linkLabel: string;
  link: string;
  timescale: string;
}

interface ISignupCard extends ICardBase {
  label: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const PriceCard = ({ heading, listItems, price, linkLabel, link, timescale }: IPriceCard) => (
  <div style={{ width: 350, maxWidth: '95%' }} className={cardWrapperStyles}>
    <div className={cardStyles}>
      <div className={cardHeaderSectionStyles}>
        <span className={cardHeaderTextStyles}>{heading}</span>
      </div>
      <div className={cardBodyStyles}>
        <ul className={cardListStyles}>
          {listItems.map(item => (
            <li key={item} className={cardListItemStyles}>
              <BsCheckCircle className="mr-2 w-1/12" />
              <p className="w-11/12">{item}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className={cardFooterStyles}>
        <div className="mb-2 flex flex-col">
          <span className={cardPriceStyles}>{price}</span>
          <span className="text-primary text-xs">{timescale}</span>
        </div>
        <ContrastLinkButton to={link} label={linkLabel} />
      </div>
    </div>
  </div>
);

export const SignUpCard = ({ heading, listItems, price, label, onClick }: ISignupCard) => (
  <div style={{ minWidth: 325 }} className={cardWrapperStyles}>
    <div className={cardStyles}>
      <div className={cardHeaderSectionStyles}>
        <span className={cardHeaderTextStyles}>{heading}</span>
        {/* Coming soon tag, hangs over page width        
        <div className="absolute bg-contrast-dark bg-opacity-70 text-white top-5 py-4 px-2 -right-2 border-2 border-white rounded-full transform rotate-45">
          Coming Soon
        </div> */}
      </div>
      <div className={cardBodyStyles}>
        <ul className={cardListStyles}>
          {listItems.map(item => (
            <li key={item} className={cardListItemStyles}>
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className={cardFooterStyles}>
        <div>
          <span className={cardPriceStyles}>{price}</span> per month
        </div>
        <PrimaryActionButton onClick={onClick} label={label} />
      </div>
    </div>
  </div>
);
