import React from 'react';

import { ContrastPriceBanner } from '../page-components/banners/banners';

const PriceBannerSection = ({
  backgroundColor,
  textColor,
}: {
  backgroundColor: 'bg-primary-dark' | 'bg-primary-lighest';
  textColor: 'text-white' | 'text-primary-dark';
}) => (
  <div
    className={`${backgroundColor} ${textColor} page-section-padding flex flex-col md:flex-row justify-around items-center`}
  >
    <div className="w-2/3 md:w-1/2 flex-center">
      <ContrastPriceBanner heading="Lessons" price="£17.50" buttonLabel="Book Now" timeScale="30 mins" />
    </div>
    <div className="py-10 px-4 w-full md:w-1/2 mb-5">
      <div className="px-4 border-l-2 border-contrast py-2">
        <h3 className="text-lg font-bold mb-4">
          Improve your guitar playing with a teacher that understands your needs
        </h3>
        <p className="text-content mb-4">
          I’ve been round the block in regards to guitar education and I understand how difficult it can be to learn
          from random Youtube videos, poorly structured online courses or 1-1 lessons from disinterested teachers that
          only care about being on stage. (you’re not alone!) So if you’re stuck in a rut, I think I can help you.
        </p>
        <p className="text-content">
          I really <span className="font-bold">DO</span> care and am passionate about music education. So let’s book in
          and see how I can help you unlock your potential!
        </p>
      </div>
    </div>
  </div>
);

export default PriceBannerSection;
