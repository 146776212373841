import React, { ReactNode } from 'react';

import { ISectionColor } from '../../types/types';

interface Props extends ISectionColor {
  children: ReactNode;
  tailwindStyle: string;
  heading: string;
  text: string;
  reverse: boolean;
  caption: string;
}

const ImageSection = ({
  backgroundColor,
  tailwindStyle,
  textColor,
  children,
  heading,
  text,
  reverse,
  caption,
}: Props) => (
  <div
    style={{ minHeight: '350px' }}
    className={`${backgroundColor} ${textColor} ${tailwindStyle} md:flex items-center justify-evenly ${
      reverse ? 'flex-row-reverse' : ''
    }`}
  >
    <div className="relative md:w-1/2">
      <div className="h-full w-full flex-center">{children}</div>
      {caption && (
        <span className="absolute text-center w-full bottom-0 py-2 text-white transparent-bg">{caption}</span>
      )}
    </div>
    <div className="md:w-1/2 py-10 px-4 md:px-10 w-full">
      <div className="border-l-2 border-contrast px-4 py-2">
        <h3 className="font-bold mb-2 text-lg">{heading}</h3>
        <p className="text-content">{text}</p>
      </div>
    </div>
  </div>
);

export default ImageSection;
