import React from 'react';
import { ISectionColor } from '../../types/types';

import { PriceCard } from '../page-components/cards/cards';

const PriceCardSection = ({ backgroundColor, textColor }: ISectionColor) => (
  <div
    className={`${backgroundColor} ${textColor} page-section-padding text-white md:flex md:flex-row-reverse justify-evenly items-center`}
  >
    <div className="md:w-1/2 mb-6 md:mb-0 px-4">
      <div className="border-l-2 border-contrast px-4 py-2 max-w-3xl mx-auto">
        <h2 className="section-heading uppercase mb-4">Online and Face-to-Face Lessons</h2>
        <p className="mb-4">
          Lessons are available both remotely online and in person at Jonny’s home studio in Edgeley, Stockport.
        </p>
        <p className="mb-4">
          Whether you want to learn Pop, Rock, Blues, folk, Country, or delve into a variety of styles - I can give you
          the right pathway to guide you on that journey.
        </p>
        <p className="mb-2">Say goodbye to bad habits, dead ends and an end to confusion.</p>
      </div>
    </div>
    <div className="card-wrapper md:w-1/2 self-center">
      <PriceCard
        heading="Lessons"
        listItems={[
          'Available online and in person (Edgeley, Stockport)',
          'Learn step by step at your own pace',
          'Structured program from beginner to advanced ',
          'Receive specific practice goals to improve your playing ',
        ]}
        price="£17.50"
        timescale="per 30 mins"
        linkLabel="Book Now"
        link="/contact/"
      />
    </div>
  </div>
);

export default PriceCardSection;
