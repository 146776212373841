import { useState, useEffect, useCallback, RefObject } from 'react';

function getPrevElement(list: HTMLElement[]): HTMLElement | null {
  const sibling = list[0].previousElementSibling;

  if (sibling instanceof HTMLElement) {
    return sibling;
  }

  return null;
}

function getNextElement(list: HTMLElement[]): HTMLElement | null {
  const sibling = list[list.length - 1].nextElementSibling;

  if (sibling instanceof HTMLElement) {
    return sibling;
  }
  return null;
}

const usePosition = (ref: RefObject<HTMLElement>) => {
  const [prevElement, setPrevElement] = useState<HTMLElement | null>(null);
  const [nextElement, setNextElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!ref?.current) return () => {};
    const element = ref.current;

    const update = () => {
      const rect = element.getBoundingClientRect();
      const visibleElements = Array.from(element.children).filter(child => {
        const childRect = child.getBoundingClientRect();

        return rect.left <= childRect.left && rect.right >= childRect.right;
      }) as HTMLElement[];
      if (visibleElements.length > 0) {
        setPrevElement(getPrevElement(visibleElements));
        setNextElement(getNextElement(visibleElements));
      }
    };

    update();
    element.addEventListener('scroll', update, { passive: true });
    return () => {
      (element.removeEventListener as (
        type: string,
        listener: (event: Event) => void,
        options?: { useCapture?: boolean; passive?: boolean },
      ) => void)('scroll', update, { passive: true });
    };
  }, [ref]);

  const scrollToElement = useCallback(
    element => {
      const currentNode = ref.current;

      if (!currentNode || !element) return;

      const newScrollPosition =
        element.offsetLeft + element.getBoundingClientRect().width / 2 - currentNode.getBoundingClientRect().width / 2;

      currentNode.scroll({
        left: newScrollPosition,
        behavior: 'smooth',
      });
    },
    [ref],
  );

  const scrollRight = useCallback(() => scrollToElement(nextElement), [scrollToElement, nextElement]);

  const scrollLeft = useCallback(() => scrollToElement(prevElement), [scrollToElement, prevElement]);

  return {
    hasItemsOnLeft: prevElement !== null,
    hasItemsOnRight: nextElement !== null,
    scrollLeft,
    scrollRight,
  };
};

export default usePosition;
