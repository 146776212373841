import React from 'react';

import { PrimaryLinkButton, ContrastLinkButton } from '../buttons/buttons';

const wrapperStyles = 'rounded-full border-2 border-white py-8 px-12 flex flex-col justify-around items-center';
const headerStyles = 'emphasis';
const priceStyles = 'text-3xl tracking-wider font-semibold';
const timeScaleStyles = 'text-sm mb-2';

const smallWrapperStyles = 'rounded-full border-2 border-white  flex flex-col justify-around items-center';
const smallPriceStyles = 'text-3xl font-semibold';
const smallTimeScaleStyles = 'text-xs mx-auto mb-2';

type IBanner = {
  heading: string;
  price: string;
  buttonLabel?: string;
  timeScale?: string;
  additionalStyle?: string;
};

export const ContrastPriceBanner = ({ heading, price, buttonLabel, timeScale }: IBanner) => (
  <div style={{ maxWidth: '95%' }} className={`${wrapperStyles} bg-contrast`}>
    <p className={headerStyles}>{heading}</p>
    <span className={priceStyles}>{price}</span>
    <span className={timeScaleStyles}>per {timeScale}</span>
    {buttonLabel && <PrimaryLinkButton to="/contact/" label={buttonLabel} />}
  </div>
);

export const SmallContrastPriceBanner = ({ additionalStyle, heading, price, buttonLabel, timeScale }: IBanner) => (
  <div style={{ maxWidth: '95%' }} className={`${smallWrapperStyles} ${additionalStyle} bg-contrast`}>
    <p className={headerStyles}>{heading}</p>
    <span className={smallPriceStyles}>{price}</span>
    <span className={smallTimeScaleStyles}>per {timeScale}</span>
    {buttonLabel && <PrimaryLinkButton to="/contact/" label={buttonLabel} />}
  </div>
);

export const PrimaryPriceBanner = ({ heading, price, buttonLabel, timeScale }: IBanner) => (
  <div style={{ maxWidth: '95%' }} className={`${wrapperStyles} bg-primary-light`}>
    <p className={headerStyles}>{heading}</p>
    <span className={priceStyles}>{price}</span>
    <span className={timeScaleStyles}>per {timeScale}</span>
    <ContrastLinkButton to="/contact/" label={buttonLabel} />
  </div>
);
