import React, { useState, Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { ContrastSubmitButton, PrimaryActionButton, ContrastActionButton } from '../../buttons/buttons';

const InputGroup = ({ label, name }: { label: string; name: string }) => (
  <>
    <label htmlFor={name} className="form-label text-primary">
      {label}
    </label>
    <Field
      id={name}
      name={name}
      type="text"
      className="form-input"
      placeholder={`Please enter your ${label.toLowerCase()}`}
    />
    <ErrorMessage name={name} render={msg => <p className="error-message">{msg}</p>} />
  </>
);

type FormValues = Record<string, string>;

export const FormikForm = ({
  onSubmit,
  setShowModal,
}: {
  onSubmit: (values: FormValues) => void;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const initialValues: FormValues = {
    signUpName: '',
    signUpEmail: '',
  };

  const schema = {
    signUpName: Yup.string().required('Required'),
    signUpEmail: Yup.string().email('Email must be a valid email address').required('Please provide an email address'),
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object(schema)}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      <Form>
        <div className="input-group">
          <InputGroup name="signUpName" label="Name" />
        </div>
        <div className="input-group">
          <InputGroup name="signUpEmail" label="Email" />
        </div>
        <div className="flex items-center justify-evenly max-w-xs mx-auto">
          <ContrastSubmitButton label="Sign Up" />
          <PrimaryActionButton onClick={() => setShowModal(false)} label="Cancel" />
        </div>
      </Form>
    </Formik>
  );
};

const SignupForm = ({ setShowModal }: { setShowModal: Dispatch<SetStateAction<boolean>> }) => {
  const [notificationMessage, setNotificationMessage] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      await axios.post('/.netlify/functions/add-mailchimp-subscriber', {
        ...values,
      });
      setNotificationMessage('Thanks for subscribing. look out for the latest updates!');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setNotificationMessage('Oops, something went wrong.');
    }
  };

  if (loading) {
    return <p className="text-center text-lg font-semibold italic py-4">Signing you up . . . </p>;
  }

  return (
    <>
      {!notificationMessage ? (
        <>
          <p style={{ maxWidth: '50ch' }} className="text-primary text-center mb-2">
            Enter your name and email below to sign up to receive the latest updates about the Jonny Ball Guitar
            Beginner&apos;s Course
          </p>
          <FormikForm onSubmit={handleSubmit} setShowModal={setShowModal} />
        </>
      ) : (
        <div className="flex-center flex-col text-primary px-2">
          <p className="text-center mb-2">{notificationMessage}</p>
          <ContrastActionButton
            onClick={() => {
              setNotificationMessage(null);
              setShowModal(false);
            }}
            label="Dismiss"
          />
        </div>
      )}
    </>
  );
};

export default SignupForm;
