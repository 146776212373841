import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { LargeContrastLinkButton, ContrastLinkButton } from '../page-components/buttons/buttons';

import { ISectionColor } from '../../types/types';

const CTA = ({ backgroundColor, textColor }: ISectionColor) => (
  <div className={`${backgroundColor} ${textColor} flex flex-col-reverse md:flex-row justify-evenly items-center`}>
    <div className="md:w-1/2 text-center px-5 py-10 md:py-0 md:px-10">
      <h4 className="text-lg mb-6 md:mb-10 md:text-3xl">
        It&apos;s never too late to begin <span className="italic underline">your</span> personal guitar journey
      </h4>
      <div className="hidden md:inline-block">
        <LargeContrastLinkButton to="/contact/" label="Book a Lesson Now" />
      </div>

      <div className="md:hidden inline-block">
        <ContrastLinkButton to="/contact/" label="Book a Lesson Now" />
      </div>
    </div>
    <StaticImage
      className="md:w-1/2"
      src="../../images/newcta.jpg"
      layout="constrained"
      height={500}
      quality={95}
      placeholder="blurred"
      formats={['auto', 'webp', 'avif']}
      alt="Learn guitar with Jonny Ball"
    />
  </div>
);

export default CTA;
